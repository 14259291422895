<script setup lang="ts">
import modal from '/~/core/mdl'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import FraudAndAmlScreeningWarning from './fraud-and-aml-screening-warning.vue'

const props = withDefaults(
  defineProps<{
    onClose?: () => void
    onCancel?: () => void
  }>(),
  {
    onClose: () => {},
    onCancel: () => {},
  }
)

function onClose() {
  props.onClose()
}

function onCancel() {
  modal.hide()
  props.onCancel()
}
</script>

<template>
  <base-mdl width="sm" :layout="false">
    <fraud-and-aml-screening-warning @close="onClose" @cancel="onCancel" />
  </base-mdl>
</template>
